<template>
    <div class="row">
        <div class="col-xl-8" v-html="siteContent">
        </div>
        <div class="col col-xl-4">
            <div class="card hide-print">
                <div class="card-header"><i class="fa fa-envelope"></i> {{ $t('contacts.question') }}</div>
                <div class="card-body">
                    <form id="form">
                        <div class="form-group">
                            <label for="email-subject">{{ $t('contacts.subject') }}</label>
                            <multiselect
                                v-model="subject"
                                :options="subjects"
                                :placeholder="$t('contacts.placeholder')"
                                :select-label="$t('enterToSelect')"
                                :deselect-label="$t('enterToDeselect')"
                                :selected-label="$t('selectedLabel')"
                                id="email-subject"
                            >
                            </multiselect>
                        </div>
                        <div class="form-group">
                            <input
                                type="text"
                                v-model="name"
                                del="name"
                                class="form-control"
                                id="exampleInputName1"
                                aria-describedby="nameHelp"
                                :placeholder="$t('contacts.name')"
                            />
                        </div>
                        <div class="form-group">
                            <input
                                type="email"
                                v-model="email"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                :placeholder="$t('contacts.email')"
                            />
                        </div>
                        <div class="form-group">
                        <textarea
                            class="form-control"
                            v-model="emailContent"
                            :placeholder="$t('contacts.questionContent')"
                        ></textarea>
                        </div>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="send"
                        >
                            {{ $t('contacts.send') }}
                        </button>
                    </form>
                </div>
            </div>
        </div>
        <captcha ref="captcha"/>
    </div>
</template>

<script>
import PortsDataService from "../service/PortsDataService";
import EventBus from "../eventBus";
import Multiselect from "vue-multiselect";
import Captcha from "@/components/Captcha.vue";

export default {
    name: "Contacts",
    refs: {
        captcha: Captcha
    },
    data() {
        return {
            subject: "",
            name: "",
            email: "",
            siteContent: "",
            emailContent: "",
            subjects: []
        };
    },
    components: { Captcha, Multiselect },
    methods: {
        async send() {
            if (
                this.subject.code === "" ||
                this.name === "" ||
                this.email === "" ||
                this.emailContent === ""
            ) {
                EventBus.$emit("FLASHMESSAGE", { message: this.$i18n.t('portMessage.fail'), error: true});
                return;
            }
            
            const captchaToken = await this.$refs.captcha.getCaptchaToken("ports_public_web_contact_form");
            await PortsDataService.sendEmail(
                this.name,
                this.email,
                this.subject,
                this.emailContent,
                captchaToken
            ).then(response => {
                EventBus.$emit("FLASHMESSAGE", { message: this.$i18n.t('portMessage.success'), error: false});
                this.name = "";
                this.email = "";
                this.subject = "";
                this.emailContent = "";
            }).catch(error => {
                const errors = Object.values(error.response.data.messages).flat().join(' ');
                EventBus.$emit("FLASHMESSAGE", { message: errors, error: true});
            })
        },
    },
    created() {
        this.siteContent = this.$i18n.t('contacts.content').join('');
        this.subjects = this.$i18n.t('contacts.subjects');
    }
};
</script>
