
import Vue from "vue";
import Component from "vue-class-component";
import i18n from "@/i18n";

@Component
export default class Captcha extends Vue {
    captchaScript!: HTMLScriptElement;
    captchaSiteKey: string = (window as any).appSettings.CaptchaSiteKey;

    created() {
        const lang = i18n.locale;
        this.captchaScript = document.createElement("script");
        this.captchaScript.setAttribute("src", "https://www.google.com/recaptcha/api.js?render=" + this.captchaSiteKey + "&hl=" + lang);
        document.head.appendChild(this.captchaScript);
    }

    destroyed() {
        const googleGeneratedScript = document.head.querySelector("script[src*='https://www.gstatic.com/recaptcha']")!;
        googleGeneratedScript.parentNode!.removeChild(googleGeneratedScript);
        
        const badgeContainer = document.body.querySelector("div[class='grecaptcha-badge']")!.parentNode!; // There's a div without any selector around the badge.
        badgeContainer.parentNode!.removeChild(badgeContainer);

        this.captchaScript.parentNode!.removeChild(this.captchaScript);
    }

    // This should be called right before using it since the token expires after 2 minutes.
    // NOTE: Action can't have hyphens.
    public async getCaptchaToken(action: string): Promise<string> {
        return await (window as any).grecaptcha.execute(this.captchaSiteKey, { action: action });
    }
}
